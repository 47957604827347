import { Content } from '@jobber/components/Content';
import { Icon } from "@jobber/components/Icon";
import { InputText } from "@jobber/components/InputText";
import { Markdown } from "@jobber/components/Markdown";
import { Modal } from '@jobber/components/Modal';
import { Spinner } from '@jobber/components/Spinner';
import { showToast } from '@jobber/components/Toast';
import { Tooltip } from "@jobber/components/Tooltip";
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { postToServer } from '../../../../components/Common/requests';
import useGetFromServer from '../../../../utils/useGetFromServer';

export default function InstructionToolTip() {
  const location = useLocation();
  const path = location.pathname;

  const params = new URLSearchParams({
    path,
    pageName: 'quote-preparation',
  })
  const { data, loading } = useGetFromServer(`pageinstructions?${params}`, [])
  const [modalOpen, setModalOpen] = useState(false)
  const [editMode, setEditMode] = useState(false);
  const [instruction, setInstruction] = useState('')

  useEffect(() => {
    if (data?.pageInstruction) {
      setInstruction(data.pageInstruction ?? 'No instruction available');
    }
  }, [data]);

  const handleSubmit = async () => {
    try {
      // Prepare the data to send
      const body = {
        path,
        pageName: 'quote-preparation',
        pageInstruction: instruction,
      };

      // Send the data to the server
      const response = await postToServer('pageinstructions', body);

      // Check for successful response
      if (response.status === 200 || response.status === 201) {
        showToast({
          message: 'Instruction updated successfully',
          variation: 'success',
        });
        if (response?.data?.pageInstruction) {
          setInstruction(response.data.pageInstruction);
        }
      } else if (response.status === 400) {
        // Handle validation errors
        const errors = response.data.errors || [response.data.message];
        showToast({
          message: `Validation failed: ${errors.join(', ')}`,
          variation: 'error',
        });
      } else if (response.status === 500) {
        // Handle server errors
        showToast({
          message: 'Server error: Please try again later.',
          variation: 'error',
        });
      } else {
        // Handle other unexpected statuses
        showToast({
          message: 'Unexpected error occurred. Please try again.',
          variation: 'error',
        });
      }
    } catch (error) {
      // Handle network or unexpected errors
      console.error('Error submitting data:', error);
      showToast({
        message: 'An error occurred while submitting the data. Please check your connection or try again.',
        variation: 'error',
      });
    }
    setEditMode(false)
  }

  if (loading) return (
    <div className='position-absolute end-0'>
      <Spinner size='small' />
    </div>
  )

  return (
    <>
      <Modal
        open={modalOpen}
        onRequestClose={() => {
          handleSubmit()
          setEditMode(false);
          setModalOpen(false)
        }}
        title='Instructions'
        primaryAction={
          editMode ? {
            label: 'Save',
            onClick: handleSubmit,
          } : {
            label: 'Edit',
            onClick: () => setEditMode(true),
          }}
      >
        <Content>
          {!editMode ?
            <Markdown content={instruction} basicUsage={false} />
            :
            <InputText
              onChange={setInstruction}
              value={instruction}
              name="instruction"
              multiline
              rows={8}
              placeholder="Page Instruction"
            />
          }
        </Content>
      </Modal>
      <div
        style={{ cursor: 'pointer' }}
        className='position-absolute end-0'
        onClick={() => setModalOpen(true)}
      >
        <Tooltip message='Instruction'>
          <Icon name='addNote' size='base' />
        </Tooltip>
      </div>
    </>
  )
}

import React, { useRef } from 'react';
import useFetchMultipleTitles from '../../_hooks/getMultipleTitle';
import useGetFromServer from '../../../../utils/useGetFromServer'
import { Combobox } from '@jobber/components/Combobox';
import { Chip } from '@jobber/components/Chip';

export default function Titles({ getTitles }) {
  const { data } = useGetFromServer('quoteTitles')
  const selected = useRef([]);
  return (
    <Combobox
      multiSelect
      label='Select Titles'
      onSelect={(selection) => {
        selected.current = selection;
        getTitles(selection.map((title) => title));
      }}
      selected={selected.current}
    >
      {data.map((title, i) => <Combobox.Option key={`${i}-${title}`} id={i} label={title} />)}
    </Combobox>
  );
}

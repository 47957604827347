import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Autocomplete } from "@jobber/components/Autocomplete";
import { Button } from '@jobber/components/Button';
import { Checkbox } from '@jobber/components/Checkbox';
import { Content } from '@jobber/components/Content';
import { Divider } from "@jobber/components/Divider";
import { Icon } from '@jobber/components/Icon';
import { InputNumber } from "@jobber/components/InputNumber";
import { Modal } from '@jobber/components/Modal';
import { showToast } from '@jobber/components/Toast';
import React, { useState } from 'react';

export default function ActionModal({ data, initialLineItemData, modalOpen, handleModalClose, handleSubmit }) {
  const [price, setPrice] = useState(0)

  const [inputs, setInputs] = useState(
    initialLineItemData
      .filter(item => !item.textOnly)
      .map(({ name, description }) => ({ id: Math.floor(Math.random() * (1000 - 10 + 1)) + 10, label: name, description, value: `${name} - ${description}` }))
  )

  function handleAddInput() {
    setInputs([...inputs, { name: '', description: '', id: Math.floor(Math.random() * (1000 - 10 + 1)) + 10 }])
  }

  function handleRemoveInput(index) {
    const delInput = [...inputs]
    delInput.splice(index, 1)
    setInputs(delInput)
  }

  function handleInputChange(val, index) {
    const newVal = [...inputs]
    newVal[index] = {
      id: newVal[index].id,
      ...val
    }
    setInputs(newVal)
  }

  const [lineText, setLineText] = useState(
    initialLineItemData
      .filter(item => item.textOnly)
      .map(({ name, description }) => ({ id: Math.floor(Math.random() * (1000 - 10 + 1)) + 10, label: name, description, value: `${name} - ${description}` }))
  )
  function handleAddLineText() {
    setLineText([...lineText, { name: '', description: '', id: Math.floor(Math.random() * (1000 - 10 + 1)) + 10 }])
  }

  function handleRemoveLineText(index) {
    const delText = [...lineText]
    delText.splice(index, 1)
    setLineText(delText)
  }

  function handleLineTextChange(val, index) {
    const newVal = [...lineText]
    newVal[index] = {
      id: newVal[index].id,
      ...val
    }
    setLineText(newVal)
  }

  const [values, setValues] = useState({
    quoteDuplicate: false,
    priceIncrease: false,
    newLineItems: false,
  });

  function handleChange(name, value) {
    if (name === 'quoteDuplicate' && value) {
      setValues({
        quoteDuplicate: true,
        priceIncrease: false,
        newLineItems: false,
      });
    }
    setValues(prevStatus => ({
      ...prevStatus,
      [name]: value,
    }));
  }

  function primaryAction() {
    if (Object.values(values).every(value => value === false)) {
      showToast({ message: "Please select an action", variation: 'info' })
      return
    }

    if (values.priceIncrease && (price <= 0 || isNaN(price))) {
      showToast({ message: "Please enter a valid price", variation: 'error' });
      return;
    }

    if (values.newLineItems) {
      const hasInvalidInputs = inputs.some(input => !input.label);
      const hasInvalidTextItems = lineText.some(text => !text.label);
      // Check if at least one valid line item or text item is present
      const hasAtLeastOneValidItem = inputs.some(input => input.label) ||
        lineText.some(text => text.label && text.description);

      if (hasInvalidInputs || hasInvalidTextItems) {
        showToast({ message: "Please fill out all line items and text items", variation: 'error' });
        return;
      }

      if (!hasAtLeastOneValidItem) {
        showToast({ message: "Please add at least one line item or text item", variation: 'error' });
        return;
      }
    }

    if (values.priceIncrease && values.newLineItems) {
      handleSubmit(
        values,
        price,
        inputs.map(({ label, description }) => ({
          name: label,
          description: description
        })),
        lineText.map(({ label, description }) => ({
          name: label,
          description: description
        }))
      );
    } else if (values.quoteDuplicate) {
      handleSubmit(values);
    } else if (values.priceIncrease) {
      handleSubmit(values, price);
    } else if (values.newLineItems) {
      handleSubmit(
        values,
        0,
        inputs.map(({ label, description }) => ({
          name: label,
          description: description
        })),
        lineText.map(({ label, description }) => ({
          name: label,
          description: description
        }))
      );
    }

    setPrice(0);
    setInputs([{ name: '', description: '' }]);
    setLineText([{ name: '', description: '' }]);
    setValues({
      quoteDuplicate: false,
      priceIncrease: false,
      newLineItems: false,
    })
    handleModalClose(false)
  }

  return (
    <Modal
      title={"Select the required action"}
      open={modalOpen}
      onRequestClose={handleModalClose}
      primaryAction={{ label: "Submit", onClick: primaryAction }}
      secondaryAction={{ label: 'Preview', onClick: () => { } }}
    >
      <Content>
        <div className='d-flex flex-column gap-3'>
          <ActionCheckbox value={values.quoteDuplicate} label={'Duplicate Quotes Exactly'} name={'quoteDuplicate'} disabled={values.newLineItems || values.priceIncrease} handleChange={handleChange} />
          <ActionCheckbox value={values.priceIncrease} label={'Increase Price'} name={'priceIncrease'} disabled={values.quoteDuplicate} handleChange={handleChange}>
            {price > 0 && <p>{price} amount will be added to the initial lineItem's price (i.e. 10 + {price})</p>}
            <InputNumber
              value={price}
              onChange={(val) => { setPrice(val) }}
              min={0}
              invalid={price === 0}
              prefix={{ label: "$" }}
            />
          </ActionCheckbox>
          <ActionCheckbox value={values.newLineItems} label={'Add new lineitems'} name={'newLineItems'} disabled={values.quoteDuplicate} handleChange={handleChange}>
            <div className='d-flex flex-column gap-2'>
              <LineItems data={data} inputs={inputs} handleInputChange={handleInputChange} handleRemoveInput={handleRemoveInput} setInputs={setInputs} />
              <Divider direction={"horizontal"} />
              <LineItems data={data} inputs={lineText} handleInputChange={handleLineTextChange} handleRemoveInput={handleRemoveLineText} setInputs={setLineText} />
              <div className='d-flex gap-2'>
                <Button fullWidth onClick={handleAddInput} icon='plus' label='Add Lineitem' />
                <Button fullWidth onClick={handleAddLineText} variation='subtle' icon='plus' label='Add Text' />
              </div>
            </div>
          </ActionCheckbox>
        </div>
      </Content >
    </Modal >
  )
}

function LineItems({ data, inputs, handleInputChange, handleRemoveInput, setInputs }) {
  function handleDragEnd(event) {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setInputs((prevInputs) => {
        const oldIndex = prevInputs.findIndex(input => input.id === active.id);
        const newIndex = prevInputs.findIndex(input => input.id === over.id);
        return arrayMove(prevInputs, oldIndex, newIndex);
      });
    }
  }

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
      <SortableContext items={inputs}>
        {inputs.map((input, index) => (
          <React.Fragment key={input?.value}>
            <ItemList data={data} index={index} input={input} handleInputChange={handleInputChange} handleRemoveInput={handleRemoveInput} />
          </React.Fragment>
        ))}
      </SortableContext>
    </DndContext>
  )
}

function ItemList({ data, index, input, handleInputChange, handleRemoveInput, type = 'primary' }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: input.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className='d-flex align-items-center gap-2'>
      <div {...listeners} {...attributes}>
        <Icon name='sort' />
      </div>
      <Autocomplete
        initialOptions={data}
        placeholder={"Name"}
        value={input}
        onChange={(newValue) => handleInputChange(newValue, index)}
        getOptions={getOptions}
        allowFreeForm={false}
        size='small'
        invalid={input?.name === '' || !input}
      />
      <Button onClick={() => handleRemoveInput(index)} type={type} icon='trash' variation='destructive' />
    </div>
  )

  function getOptions(text) {
    if (text === "") {
      return data;
    }
    const filterRegex = new RegExp(text, "i");

    return data.filter((option) => option.label.match(filterRegex));
  }
}

function ActionCheckbox({ value, label, name, children, disabled = false, handleChange }) {
  return (
    <div>
      <Checkbox
        disabled={disabled}
        label={label}
        name={name}
        checked={value}
        onChange={(e) => handleChange(name, e)}
      />
      {value && children &&
        <div className='p-2'>
          {children}
        </div>
      }
    </div>
  )
}
